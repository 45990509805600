<template>
  <v-card
    flat
    class="pa-0 mt-2"
  >
    <v-card-title class="flex-nowrap">
      <v-icon class="text--primary me-3">
        {{ icons.mdiFlashOutline }}
      </v-icon>
      <span class="text-break">Webhook Settings</span>
    </v-card-title>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            v-show="mode === 'update'"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="webhookData.id"
              label="Webhook ID"
              dense
              outlined
              :disabled="true"
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="webhookData.label"
              label="Label"
              clearable
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="webhookData.status"
              :rules="requiredRule"
              dense
              outlined
              label="Status *"
              :items="status"
            ></v-select>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="webhookData.config.url"
              label="URL *"
              :rules="requiredRule"
              prefix="POST"
              placeholder="https://example.com"
              dense
              clearable
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="webhookData.config.authorization_header"
              label="Authorization Header"
              placeholder="Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9... or Basic dXNlcjpwd2Q="
              dense
              clearable
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <!--
        <v-row>
          <v-col
            v-show="webhookData.created_at || webhookData.updated_at"
            style="font-size:8pt"
            cols="12"
          >
            <div
              v-show="webhookData.created_at"
            >
              Created at: {{ webhookData.created_at }} by {{ webhookData.created_by }}
            </div>
            <div
              v-show="webhookData.updated_at"
            >
              Updated at: {{ webhookData.updated_at }} by {{ webhookData.updated_by }}
            </div>
          </v-col>
        </v-row>
        -->

        <v-row>
          <v-col
            md="12"
            cols="6"
          >
            <div class="text-sm text--primary">
              The provided <strong>target URL</strong> for this webhook receives a <strong>JSON</strong> payload via <strong>POST</strong> request with the information of:<br /><br />
              <ul>
                <li><strong>detail</strong></li>
                <ul>
                  <li><strong>otp</strong>: the 6-digit one-time password</li>
                  <li><strong>expires_at</strong>: expiration date as ISO string</li>
                  <li><strong>account</strong>: account id</li>
                  <li><strong>enrollment</strong>: enrollment id</li>
                  <li><strong>tenant</strong>: tenant id</li>
                </ul>
                <li><strong>source</strong>: fixed to "quasr.extensions"</li>
                <li><strong>detail-type</strong>: fixed to "otp:create"</li>
                <li><strong>time</strong>: timestamp when the webhook triggered, as ISO string</li>
              </ul>
              <br />
              Example payload:<br />
              <div class="code_block">
                {<br />
                &nbsp;&nbsp;'source': 'quasr.extensions',<br />
                &nbsp;&nbsp;'detail-type': 'otp:create',<br />
                &nbsp;&nbsp;time: '2022-01-05T10:40:00.155Z'<br />
                &nbsp;&nbsp;detail: {<br />
                &nbsp;&nbsp;&nbsp;&nbsp;otp: '123456',<br />
                &nbsp;&nbsp;&nbsp;&nbsp;expires_at: '2022-01-05T10:55:00.155Z',<br />
                &nbsp;&nbsp;&nbsp;&nbsp;account: 'a1ca3a07-d2e8-4aee-9006-88bc3a4fc726',<br />
                &nbsp;&nbsp;&nbsp;&nbsp;enrollment: '4cfd145c-d48c-4fbc-b5f0-21b7fb21c31a',<br />
                &nbsp;&nbsp;&nbsp;&nbsp;tenant: '90e8207e-8d09-4a08-8272-2f1672650ef1'<br />
                &nbsp;&nbsp;}<br />
                },<br />
                <br />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiAlertOutline, mdiCloudUploadOutline, mdiEyeOffOutline, mdiEyeOutline, mdiFormSelect, mdiFlashOutline,
} from '@mdi/js'

export default {
  props: {
    webhookData: {
      type: Object,
      default: () => {},
    },
    mode: { type: String, default: undefined },
  },
  data() {
    return {
      requiredRule: [
        value => !!value || 'Required',
      ],
    }
  },

  /**
   * methods
   */
  methods: {
    df(isoString) {
      return new Date(isoString).toLocaleString()
    },
    cancel() {
      this.$router.back()
    },
  },
  setup() {
    const status = [
      { text: 'Enabled', value: 'ENABLED' },
      { text: 'Disabled', value: 'DISABLED' },
    ]

    return {
      status,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiFormSelect,
        mdiFlashOutline,
      },
    }
  },
}
</script>
